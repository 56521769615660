var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-container', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h5', {
    staticClass: "right"
  }, [_vm._v("View Address")]), _c('button', {
    staticClass: "left",
    on: {
      "click": function click() {
        return _this.$bvModal.show('bv-add-address');
      }
    }
  }, [_vm._v(" Add Address ")]), _c('b-modal', {
    attrs: {
      "id": "bv-add-address",
      "size": "lg",
      "hide-footer": true,
      "hide-header": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
      "centered": "",
      "header-class": "my-class",
      "body-class": "p-0"
    }
  }, [_vm.$options.components['address-add-address-modal'] ? [_c('address-add-address-modal')] : _vm._e()], 2)], 1)]), this.deliveryAddress.length !== 0 ? _c('div', {
    staticClass: "body"
  }, _vm._l(_vm.deliveryAddress, function (address, index) {
    return _c('div', {
      key: index
    }, [_c('div', [_c('b-card-text', [_c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('p', {
      staticClass: "heading-name",
      attrs: {
        "value": address
      }
    }, [_vm._v(" " + _vm._s(address.fullName) + " "), address.isDefault ? _c('large', {
      staticClass: "default-address-class ml-5"
    }, [_vm._v(" Default ")]) : _vm._e()], 1), _c('div', {}, [_vm.$store.state['address'].deleteAddressLoading != address._id ? _c('span', {
      staticClass: "float-right"
    }, [_c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "src": "img/address/Trash.png",
        "alt": ""
      },
      on: {
        "click": function click() {
          return _vm.deleteAddress(address._id);
        }
      }
    }), _c('img', {
      staticClass: "edit-icon-style",
      attrs: {
        "src": "img/EditIcon.png",
        "alt": ""
      },
      on: {
        "click": function click() {
          _vm.editAddressClick(address);

          _vm.$bvModal.show(address._id);
        }
      }
    }), _c('b-modal', {
      attrs: {
        "id": address._id,
        "size": "lg",
        "hide-footer": true,
        "hide-header": true,
        "no-close-on-esc": true,
        "no-close-on-backdrop": true,
        "centered": "",
        "header-class": "my-class",
        "body-class": "p-0"
      }
    }, [_vm.$options.components['address-edit-address-modal'] ? [_c('address-edit-address-modal', {
      ref: "editAddressRef",
      refInFor: true,
      attrs: {
        "address": address
      }
    })] : _vm._e()], 2)], 1) : _vm._e(), _vm.$store.state['address'].deleteAddressLoading == address._id ? _c('span', {
      staticClass: "float-right"
    }, _vm._l(3, function (i) {
      return _c('b-spinner', {
        key: i,
        staticClass: "mr-1",
        attrs: {
          "small": "",
          "label": "Small Spinner",
          "type": "grow"
        }
      });
    }), 1) : _vm._e()])]), _c('p', {
      staticClass: "mr-1",
      attrs: {
        "alt": ""
      }
    }, [_vm._v(" " + _vm._s(address.houseNumber) + ", " + _vm._s(address.street1) + ", ")]), _c('p', {
      staticClass: "mr-1",
      staticStyle: {
        "line-height": "10px"
      },
      attrs: {
        "width": "10px",
        "alt": ""
      }
    }, [_vm._v(" " + _vm._s(address.street2) + ", " + _vm._s(address.city) + ", ")]), _c('p', {
      staticClass: "mr-1",
      staticStyle: {
        "line-height": "10px"
      },
      attrs: {
        "width": "10px",
        "alt": ""
      }
    }, [_vm._v(" " + _vm._s(address.state) + ", " + _vm._s(address.zip) + " ")])]), index < _vm.len - 1 ? _c('hr') : _vm._e()], 1)]);
  }), 0) : _vm._e(), this.deliveryAddress.length == 0 ? _c('p', {
    staticClass: "add-address text-center"
  }, [_vm._v(" Please Add Address ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }